<!-- eslint-disable no-param-reassign -->
<script setup>
import { ref, onMounted, onUnmounted } from "vue";

const snowCanvas = ref(null);
let animationFrameId;
let flakes = [];

const createSnowflakes = (canvas, numFlakes = 50) => {
	const ctx = canvas.getContext("2d");
	flakes = [];

	for (let i = 0; i < numFlakes; i += 1) {
		flakes.push({
			x: Math.random() * canvas.width,
			y: Math.random() * canvas.height,
			radius: Math.random() * 3 + 1,
			speed: Math.random() * 2,
			opacity: Math.random() * 0.7 + 0.3,
			wind: Math.random() * 2 - 1,
		});
	}

	return ctx;
};

const animateSnowflakes = (canvas, ctx) => {
	const render = () => {
		ctx.clearRect(0, 0, canvas.width, canvas.height);
		ctx.fillStyle = "rgba(255, 255, 255, 1)";

		for (let i = 0; i < flakes.length; i += 1) {
			const flake = flakes[i];
			ctx.beginPath();
			ctx.globalAlpha = flake.opacity;
			ctx.arc(flake.x, flake.y, flake.radius, 0, Math.PI * 2);
			ctx.fill();

			flake.y = flake.y + flake.speed > canvas.height ? -flake.radius : flake.y + flake.speed;
			flake.x =
				flake.x + flake.wind > canvas.width || flake.x + flake.wind < 0
					? Math.random() * canvas.width
					: flake.x + flake.wind;
		}

		animationFrameId = requestAnimationFrame(render);
	};

	render();
};

onMounted(() => {
	const canvas = snowCanvas.value;
	const { innerWidth: width, innerHeight: height } = window;
	canvas.width = width;
	canvas.height = height;

	const ctx = createSnowflakes(canvas, 50);
	animateSnowflakes(canvas, ctx);

	const resizeHandler = () => {
		canvas.width = window.innerWidth;
		canvas.height = window.innerHeight;
		flakes.forEach((flake) => {
			flake.x = Math.random() * canvas.width;
			flake.y = Math.random() * canvas.height;
		});
	};

	window.addEventListener("resize", resizeHandler);

	onUnmounted(() => {
		cancelAnimationFrame(animationFrameId);
		window.removeEventListener("resize", resizeHandler);
	});
});
</script>

<template>
	<div class="snow-container">
		<canvas ref="snowCanvas" class="snow-canvas"></canvas>
	</div>
</template>

<style scoped lang="scss">
@import "./Snowflake.scss";
</style>
